<template>
  <div class="content-page">
    
    <div :class="{'sponsored': true, 'sponsored-modal-opened': showSponsored}">
      <div class="sponsored-top">
        <img class="sponsored-star" :src="require('@/assets/btn-sponsored.png')" @click="toggleModalSponsored()"/>
        <div v-if="showSponsored" class="close-sponsored-modal pi pi-times" @click="toggleModalSponsored()"></div>
      </div>
        <div v-for="team in sponsoredTeams" v-if="showSponsored" :key="team.teamId" class="sponsored-teams" @click="viewLeagueTeam(team)">
          <img :src="team.image_url"/>
        </div>
    </div>

    <div class="points-ranking flex justify-content-center">
      <div class="item-points-ranking flex flex-column bg-auto bg-no-repeat">
        <div class="flex flex-nowrap w-full justify-content-center align-items-end gap-3">
          <img :src="require('@/assets/icons/total-points.png')"/>
          <div>{{ $t('home.total_points') }}</div>
        </div>
        <div>{{ currentPoints }}</div>      
      </div>
      <div class="item-points-ranking flex flex-column bg-auto bg-no-repeat" @click="goToRoute('leaders')">
        <div class="flex flex-nowrap w-full justify-content-center align-items-end gap-3">
          <img :src="require('@/assets/icons/ranking.png')"/>
          <div>{{ $t('home.ranking') }}</div>
        </div>
        <div> {{ currentRanking }}</div>      
      </div>
    </div>
    
    <div id="cards-home-div" class="cards flex gap-3 ml-4 pr-4">
      <div v-for="card in cards" :key="card.id" :class="`item-card-${card.type} flex bg-auto bg-no-repeat`" :style="language != 'en'? 'direction: rtl;' : ''">
        <div class="content-card flex flex-column" :class="{'content-card-rtl':language != 'en'}">
          <div class="title-card"><span>{{ card.type }}</span>{{ $t('cards.card') }}</div>
          <div class="price-card"> {{ interpolate($t("home.points_per_card"),[cardsPrices.points[card.type].correct])  }}</div>
          <div class="button-card" @click="buyCards(card.type)" v-if="card.type != 'daily'"> {{ $t("cards.buy_card") }}</div>
        </div>
        <div class="amount-card bg-no-repeat">
          <div class="total">{{ userCards[card.type] }}</div>
        </div>
      </div>
    </div>

    <div class="matches-selector flex justify-content-between mx-4 align-items-center">
      <div :class="{ selected: matchSelector == 'live' }" @click="setMatchSelector('live')">{{ $t('home.live') }}</div>
      <div :class="{ selected: matchSelector == 'upcoming' }" @click="setMatchSelector('upcoming')">{{ $t('home.upcoming') }}</div>
      <div :class="{ selected: matchSelector == 'past' }" @click="setMatchSelector('past')">{{ $t("home.past_matches") }}</div>
      <div class="text-white" @click="viewAllMatches()">{{ $t("home.view_all") }}</div>
    </div>

    <div id="matches-home-div" class="matches flex">
      <div v-for="match in matches[matchSelector]" :key="match.id" class="match flex flex-column">
        <div :class="{'title': true, 'join-play': matchSelector == 'upcoming', 'joined': (match.bets && match.bets.length > 0) }" @click="playMatch(match)">
          {{ getMatchTitle(match) }}
        </div>
        <div class="time">{{ matchSelector == 'upcoming' ? moment(match.matchStart).format("HH:mm") : '' }}</div>
        <div class="teams">
          <img :src="match.homeTeam.flag"/>
          <img :src="match.awayTeam.flag"/>
        </div>
        <div class="score">
          <div class="team-name">{{ match.homeTeam["name_" + language] }}</div>
          <div>{{ matchSelector == 'upcoming' ? '-' : match.homeScore || 0}}</div>
        </div>
        <div class="score">
          <div class="team-name">{{ match.awayTeam["name_" + language] }}</div>
          <div>{{ matchSelector == 'upcoming' ? '-' : match.awayScore || 0}}</div>
        </div>
      </div>
    </div>
    
    <div class="header-leagues mx-4">
      <div>{{ $t("home.leagues") }}</div>
      <div class="text-white" @click="viewAllLegues">{{ $t("home.view_all") }}</div>
    </div>

    <div id="leagues-home-div" class="leagues">
      <div v-for="league in leagues" :key="league.current.id" class="league" @click="viewLeague(league.current.id, league.year)">
        <img :src="league.current.logo"/>
        <div>{{ league.current['name_' + language ] }}</div>
      </div>
    </div>

    <div class="top-matches-content">
      
      <div class="top-matches-header">{{ $t("home.top_3_matches") }}</div>
      <div id="dates-home-div" class="top-matches">
        <div class="dates">
          <div v-for="matchDay in matchDays" :key="matchDay.id" @click="setMatchDay(matchDay.id)" :class="{selected: currentMatchDayID == matchDay.id}">          
            <div v-if="currentDate == matchDay.day" class="today"> {{ "Today" }} </div>
            <div v-else class="date">
              <div>{{ moment(matchDay.day).format('DD')}}</div>
              <div>{{ moment(matchDay.day).format('MM') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="top-matches-list flex flex-column">
        <div v-for="match in topMatches" :key="match.id" @click="viewMatch(match.id)" class="top-matches-item w-full flex justify-content-between align-items-center">
          <div class="teams-flag">
            <img :class="{'loser-opacity': match.winner == 'A'}" :src="match.homeTeam.flag"/>
            <img :class="{'loser-opacity': match.winner == 'H'}" :src="match.awayTeam.flag"/>
          </div>
          <div class="flex flex-column justify-content-between">
            <div class="match-teams flex justify-content-between">
              {{ match.homeTeam["name_" + language] }} <span class="versus">{{ $t("home.versus") }}</span> {{ match.awayTeam["name_" + language] }}
            </div>
            <div class="match-status">
              <span v-if="match.winner == null">
                {{ $t("league.match_continues") }}
              </span>
              <span v-else>
                <!-- {{  }} -->
                <span v-if="match.winner == 'D'">
                  {{ $t("league.draw_over") }}
                </span>
                <span v-else>
                  <span v-if="language == 'en'">
                    {{ $t("league.match_over") + ' ' + ( match.winner == 'H' ? match.homeTeam["name_" + language] : match.awayTeam["name_" + language] ) + ' ' + $t("league.won") }}
                  </span>
                  <span v-else>
                    {{ $t("league.won") + ' ' + ( match.winner == 'H' ? match.homeTeam["name_" + language] : match.awayTeam["name_" + language] ) + ' ' + $t("league.match_over")}}
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div class="match-score">
            {{ !match.winner ? '-' : ((match.homeScore || 0) + '-' + (match.awayScore || 0))}}
          </div>
        </div>
      </div>

      <div class="slider-home">
        <img :src="require('@/assets/slider-home.png')" @click="viewNews()"/>
      </div>

    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import service from "../service";
import utils from "@/utils";
import moment from "moment";

export default {
  name: "HomeComponent",
  mixins: [utils],
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
      elementsToScroll: ['cards-home-div','matches-home-div','leagues-home-div','dates-home-div'],
      pinCode: [],
      otpRequested: false,
      matchSelector: 'live',
      currentPoints: 0,
      showSponsored: false,
      sponsoredTeams: [],
      currentRanking: 0,
      matches: { live:[], upcoming: [], past: [] },
      matchDays:[],
      topMatches: [],
      currentMatchDayID: 0,
      leagues: [],
      cards: [
        {
          "id": 1,
          "type": "captain",
          "price": "captain_price"
        },
        {
          "id": 2,
          "type": "gold",
          "price": "gold_price"
        },
        {
          "id": 3,
          "type": "daily",
          "price": "dayly_price"
        },
      ],
    }
  },
  methods: {
    moment: (date) => {
      return moment(date);
    },

    toggleModalSponsored(){
      this.showSponsored = !this.showSponsored
    },

    viewLeagueTeam(team){
      this.$router.push({ name: 'league' , params: { teamInfo: team } })
    },

    getMatchTitle(match){
      if(this.matchSelector == 'upcoming')
        return match.bets.length > 0 ? this.$t("home.joined") : this.$t("home.join_or_play")
      else if(this.matchSelector == 'live')
        return this.$t("home.live")
      else 
        return this.$t("home.ended")
    },

    setMatchDay(id){
      if(this.currentMatchDayID != id){
        this.currentMatchDayID = id
        this.getTopMatches()
      }
    },

    viewAllLegues(){
      utils.methods.goToRoute('leagues')
    },

    viewLeague(leagueId, year){
      let league = { "leagueId": leagueId, "year": year }
      this.$router.push({ name: 'league' , params: { leagueInfo: league } })
    },

    viewAllMatches(){
      utils.methods.goToRoute('match-list')
    },

    viewMatch(matchID) {
      let m = { "matchId": matchID };
      utils.methods.goToRouteWithParams('match', m)
    },

    viewNews(){
      utils.methods.goToRoute("news")
    },

    buyCards(type){
      store.cardsPrices.currentType = type 
      store.layout.showModalBuyCards = true
    },
    goToRoute(page){
      utils.methods.goToRoute(page)
    },

    setMatchSelector(type){
      this.matchSelector = type
      clearInterval(this.timerInterval)
      if(type == 'live'){
        this.getLiveMatches()
      }
    },

    playMatch(match){
      if(this.matchSelector != 'upcoming' || match.bets.length > 0)
        return
      else {
        this.$router.push({name: 'joined-match', params: { match: match }})
      }
    },

    async getUserData() {
      let response = await service.user.getUser()
      if (response && response.status == 200) {
        this.currentRanking = response.data.overallRank
        this.currentPoints = response.data.currentPoints
        
        store.user.name = response.data.name;
        store.user.currentPoints = this.currentPoints
        store.user.currentRanking = this.overallRank
      }
    },

    async getLeagues() {
      let response = await service.leagues.seasons();
      if (response && response.status == 200){
        this.leagues = response.data.map(d => {
          return {current: d.league, year: d.year }
        })
      }
    },

    async getSponsoredTeams() {
      let response = await service.team.getSponsoredActives(this.language);
      if (response && response.status == 200){
        this.sponsoredTeams = response.data.map(s => {
          return { teamId: s.teamId, image_url: s.image_url, leagueId: s.seasonTeam.season.leagueId, year: s.seasonTeam.season.year }
        });
      }
    },

    async getLiveMatches(){
      store.ignoreLoading = true
      store.ignoreDefaultError = true
      let response = await service.match.getLiveMatches(1, 30);
      if (response && response.data && response.status == 200) {
        this.matches.live = response.data.map((m) => {
          return {
            homeTeam: { 
              flag:  m.teams.home.logo,
              ["name_" + i18n.locale]: m.teams.home.name,
            },
            awayTeam: { 
              flag:  m.teams.away.logo,
              ["name_" + i18n.locale]: m.teams.away.name,
            },
            homeScore: m.goals.home,
            awayScore: m.goals.away
          }
        })
      }
      this.timerInterval = setTimeout(async ()=> {
        await this.getLiveMatches()
      }, 30000)
    },

    async getLastMatches() {
      let response = await service.match.getLastMatches(1, 30)
      if (response && response.status == 200){
        this.matches.past = response.data.result
      }
    },

    async getScheduleMatches(){
      let response = await service.match.getScheduleMatches(1, 30)
      if (response && response.status == 200){
        this.matches.upcoming = response.data.result
      }
    },

    async getMatchDays(){
      let response = await service.match.getMatchDays()
      if (response && response.status == 200){
        this.matchDays = response.data
        if(this.matchDays.length > 0)
          this.currentMatchDayID = this.matchDays[0].id
      }
    },

    async getTopMatches(){
      if(this.currentMatchDayID != 0){
        let response = await service.match.getTopMatches(this.currentMatchDayID)
        if (response && response.status == 200){
          this.topMatches = response.data.slice(0, 3)
        }
      }
    },

    async getCardsActives(){
      let response = await service.cards.cardsActives()
      if (response && response.status == 200){
        store.cardsPrices.points.daily = response.data.filter(c => c.type == 'daily-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0]
        store.cardsPrices.points.gold = response.data.filter(c => c.type == 'gold-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0]
        store.cardsPrices.points.captain = response.data.filter(c => c.type == 'captain-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0]
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    userCards(){
      return store.user.cards
    },
    currentDate(){
      return this.moment(new Date()).format("YYYY-MM-DD")
    },
    cardsPrices(){
      return store.cardsPrices
    }
  },

  async mounted() {   
    this.scrollBanner(this.elementsToScroll);

    await this.getUserData(),
    Promise.all([
      this.getMatchDays(),
      this.getScheduleMatches(),
      this.getLastMatches(),
      this.getLeagues(),
      this.getCardsActives(),    
      this.getTopMatches(),
      this.getSponsoredTeams()
    ]).then(async() => {
      this.getLiveMatches()
      store.layout.showHeader = true
      store.layout.showFooter = true
      store.layout.showAccountFooter = false
      store.layout.footerItem = 'home'
      store.layout.headerTitle = this.$t("home.title")
    });
  },

  beforeDestroy(){
    clearInterval(this.timerInterval);
  }
  
}
</script>

<style scoped>

  .sponsored {
    position: fixed;
    top: var(--contentTop);
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    gap: 25px;
    padding-bottom: 25px;
  }
  
  .sponsored-modal-opened {   
    background-color: #232B35;
    width: 100%;
  }

  .sponsored-top {
    display: flex;
    justify-content: space-between;
  }

  .sponsored-star {
    width: 72px;
    height: 72px;
  }
  .close-sponsored-modal {
    margin: 15px;
    color: white;
    font-size: 25px;
  }
  .content-page {
    justify-content: space-around;
    font-weight: 400;
    font-family: 'Poppins';
    font-style: normal;
    background-color: #181921;
  }
  .points-ranking {
    padding: 10px 20px;
    gap: 5px;
  }

  .points-ranking > div:nth-child(1) {
    background-image: url('@/assets/total-points.png');
    background-position:50% 50%;  /* Sets reference point to scale from */
    background-size:cover;
  }
  .points-ranking > div:nth-child(2) {
    background-image: url('@/assets/ranking-bg.png');
    background-position:50% 50%;  /* Sets reference point to scale from */
    background-size:cover;
  }
  .item-points-ranking {
    padding: 10px 0px;
    width: calc(50% - 0px);
  }

  .item-points-ranking > div {
    color: #B0B1CA;
    font-size: 12px;
  }

  .item-points-ranking > div:nth-child(2) {
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 5px;
  }

  .item-points-ranking img {
    margin: 0 0px;
  }

  .cards {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .cards::-webkit-scrollbar {
    display: none;
  }

  .cards > div {
    min-width: 245px;
    justify-content: space-between;
  }


  .item-card-captain {
    background-image: url("@/assets/card-home-capitain.png");
  }

  .item-card-gold {
    background-image: url("@/assets/card-home-gold.png");
  }

  .item-card-daily {
    background-image: url("@/assets/card-home-daily.png");
  }

  .content-card {
    color: #FFFFFF;
    align-items: start;
    padding: 15px 0px 18px 18px;
  }

  .content-card-rtl {
    padding: 15px 18px 18px 0px !important;
  }

  .title-card {
    font-size: 20px;
    text-align: left;
    text-transform: capitalize;
    font-family: 'Poppins-SemiBold';
  }

  .title-card > span {
    margin-right: 4px;
  }
  .item-card-captain .title-card > span {
    color: #FFCD00;
  }

  .item-card-gold .title-card > span {
    color: #191B22;
  }

  .item-card-daily .title-card > span {
    color: #191B22;
  }

  .price-card {
    font-family: 'Poppins-SemiBold';
    font-size: 15px;
    line-height: 12px;
    white-space: nowrap;
  }

  .button-card {
    background-color: #FFFFFF;
    font-size: 9px;
    font-weight: 700;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 18px;
  }

  .item-card-daily .button-card {
    color: #191B22;
  }

  .item-card-gold .button-card {
    color: #be9900;
  }

  .item-card-captain .button-card {
    color: #0057B7;
  }

  .amount-card {
    width:75px;
    margin: 10px;
    position: relative;
  }
  .item-card-captain .amount-card {
    background-image: url("@/assets/bg-amount-capitain.png");
  }

  .item-card-gold .amount-card {
    background-image: url("@/assets/bg-amount-gold.png");
  }

  .item-card-daily .amount-card {
    background-image: url("@/assets/bg-amount-daily.png");
  }

  .total {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    border-radius: 50%;
    padding: 4px;
    width: 26px;
    height: 26px;
    font-weight: 700;
    font-size: 16px;
    right: -5px;
    top: -3px;
  }

  .matches-selector {
    font-size: 12.5px;
    color: #B0B1CA;
    padding: 12px 0;
  }

  .matches-selector div.selected{
    background-color: #0A57B7;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins-SemiBold';
    padding: 6px 10px;
  }

  .matches {
    padding: 5px 25px;
    overflow: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    gap: 8px;
  }

  .matches::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .match {
    background-color: #282E3D;
    color: #FFFFFF;
    padding: 10px 0px 5px 0px;
    border-radius: 15px;
    min-width: 120px;
  }

  .match .score {
    font-size: 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
  }

  .match .score div {
    white-space: nowrap;
  }
  .match .score div:nth-child(2) {
    color: #FFCD00;
    font-family: 'Poppins-SemiBold';
    margin-left: 2px;
  }

  .match .time {
    padding: 3px 0;
    height: 16px;
    color: #FFCD00;
    font-size: 12px;
    font-family: 'Poppins-SemiBold';
  }

  .match .teams {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
  }

  .match .teams img {
    width: 36px;
    height: 36px;
  }

  .match .title {
    font-size: 12px;
    color: #B0B1CA;
    background-color: #191B22;
    border-radius: 8px;
    padding: 2px 12px;
    margin: 0 10px;
    align-self: start;
  }

  .match .title.join-play {
    background-color: #0A57B7;
    color: #FFFFFF;
    padding: 2px 8px;
    font-size: 12px;
  }

  .match .title.joined {
    background-color: #6d6d6d;
    color: #FFFFFF;
    padding: 2px 8px;
    font-size: 12px;
  }

  .match .team-name {
    max-width: 80px;
    height: 15px;
    overflow: hidden;
    word-break:break-all;
    white-space: normal!important;
  }

  .leagues-content {
    display: flex;
    flex-direction: column;
    padding: 15px 25px 12px 25px;
  }

  .header-leagues {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #B0B1CA;
    padding: 20px 0 5px 0;
  } 

  .leagues {
    display: flex;
    overflow: scroll;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 12px;
    padding: 5px 25px;
  }

  .leagues::-webkit-scrollbar {
    display: none;
  }

  .league {
    background-color: #282E3D;
    color: #FFFFFF;
    padding: 17px 4px 12px 4px;
    border-radius: 15px;
    min-width: calc(100vw/8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
  .league {
    min-width: calc(100vw/2.5);
  }
}

@media (min-width: 600px) {
  .league {
    min-width: calc(100vw/4.5);
  }
}

@media (min-width: 1200px) {
  .league {
    min-width: calc(100vw/8);
  }
}


  .league img {
    height: 40px;
    width: 40px;
  }

  .league div:nth-child(2) {
    font-size: 10px;
    padding-top: 4px;
  }

  .top-matches-content {
    display: flex;
    padding: 0 25px;
    flex-direction: column;
    font-size: 12px;
    color: #B0B1CA;
  }

  .top-matches {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .top-matches::-webkit-scrollbar {
    display: none;
  }

  .top-matches-header {
    padding: 15px 0px 5px 0px;
    text-align: left;
  } 

  .dates {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 6px 0px 10px 0px;
  }

  .dates > div{
    border-radius: 8px;
    color: #FFFFFF;
    padding: 1px 7px 4px 7px;
    font-size: 12px;
  }

  .dates .selected {
    background-color: #0A57B7;
  }

  .today {
    font-size: 12.5px;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins-SemiBold';
    align-content: center;
    flex-wrap: wrap;
    padding: 6px 8px;
    display: flex;
    padding-bottom: 4px!important;
  }

  .date {
    display: flex;
    flex-direction: column;
  }

  .date div {
    overflow: hidden;
    height: 15px;
  }

  .top-matches-list {
    gap: 8px;
  }

  .top-matches-item { 
    padding: 13px 10px;
    background-color: #282E3D;
    border-radius: 15px;
  }

  .teams-flag img:nth-child(2){
    margin-left: 5px;
  }

  .teams-flag img {
    vertical-align: middle;
    height: 30px;
    width: 30px;
  } 

  .teams-flag .loser-opacity {
    opacity: .4;
  }

  .match-teams {
    color: #FFFFFF;
  }
  .match-teams span {
    color: #FFCD00;
    margin: 0 5px;
  }

  .match-status {
    font-size: 11px;
  }

  .match-score {
    font-size: 17px;
    color: #FFFFFF;
  }

  .slider-home {
    margin-top: 30px;
  }

</style>
